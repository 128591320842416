// Put all the applicaiton-wide UJS stuff in here
// until we find a better place


$(function () {

  $("a[data-toggle]").on('click', function () {
    const el = $($(this).data("toggle"))
    if (el) { el.toggle() }
  })

  $('img[data-remove-on-error]').each(function () {
    $(this).on('error', function () {
      $(this).attr({ src: "data:image/gif;base64,R0lGODlhAQABAIAAAP///wAAACH5BAEAAAAALAAAAAABAAEAAAICRAEAOw==" })
      $(this).width(0)
    });
  });

})

